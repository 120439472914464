import { NgModule } from "@angular/core";
import { S25AvailabilityGridComponent } from "./s25.availability.grid.component";
import { S25IconModule } from "../s25-icon/s25.icon.module";
import { CommonModule } from "@angular/common";
import { S25ItemModule } from "../s25-item/s25.item.module";
import { S25OfficeHoursSliderModule } from "../s25-office-hours-slider/s25.office.hours.slider.module";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";
import { S25OptModule } from "../s25-opt/s25.opt.module";
import { S25ModalModule } from "../s25-modal/s25.modal.module";
import { S25CaptureEventModule } from "../s25-capture-event/s25.capture.event.module";
import { S25InfiniteScrollModule } from "../s25-infinite-scroll/s25.infinite.scroll.module";
import { S25FavoriteModule } from "../s25-favorite/s25.favorite.module";
import { S25AvailabilityGridEmbeddedComponent } from "./s25.availability.grid.embedded.component";
import { S25TimeBubbleComponent } from "../../standalone/s25.time.bubble.component";

@NgModule({
    imports: [
        S25IconModule,
        CommonModule,
        S25ItemModule,
        S25OfficeHoursSliderModule,
        S25LoadingInlineModule,
        S25OptModule,
        S25ModalModule,
        S25CaptureEventModule,
        S25InfiniteScrollModule,
        S25FavoriteModule,
        S25TimeBubbleComponent,
    ],
    exports: [S25AvailabilityGridComponent, S25AvailabilityGridEmbeddedComponent],
    declarations: [S25AvailabilityGridComponent, S25AvailabilityGridEmbeddedComponent],
    providers: [S25AvailabilityGridComponent, S25AvailabilityGridEmbeddedComponent],
})
export class S25AvailabilityModule {}
