import { Component, Input } from "@angular/core";
import { Item } from "../../pojo/Item";
import { AvailCompType, AvailService } from "../../services/avail.service";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-ng-availability-grid-embedded")
@Component({
    selector: "s25-ng-availability-grid-embedded",
    template: `
        <s25-ng-availability-grid
            [dataService]="Service.availDataService"
            [view]="view"
            [itemType]="itemType"
            [itemId]="itemId"
            [query]="query"
        ></s25-ng-availability-grid>
    `,
})
export class S25AvailabilityGridEmbeddedComponent {
    @Input() itemType: Item.Id;
    @Input() itemId: number;
    @Input() query: string;
    @Input() view: AvailCompType;

    constructor() {
        let params = new URLSearchParams(window.location.search);

        let comptype = params.get("comptype");
        let itemId = params.get("itemId");
        let queryId = params.get("queryId");
        let searchQueryIn = params.get("searchQuery");
        let itemTypeId = params.get("itemTypeId");

        if ((itemId != null || queryId != null || searchQueryIn != null) && itemTypeId != null) {
            let searchQuery = "";

            if (searchQueryIn != null) {
                searchQuery = searchQueryIn;
            } else if (itemId != null) {
                switch (itemTypeId) {
                    case "1":
                        searchQuery = "&event_id=" + itemId;
                        break;
                    case "2":
                        searchQuery = "&organization_id=" + itemId;
                        break;
                    case "4":
                        searchQuery = "&space_id=" + itemId;
                        break;
                    case "6":
                        searchQuery = "&resource_id=" + itemId;
                        break;
                }
            } else {
                switch (itemTypeId) {
                    case "1":
                        searchQuery = "&event_query_id=" + queryId;
                        break;
                    case "2":
                        searchQuery = "&organization_query_id=" + queryId;
                        break;
                    case "4":
                        searchQuery = "&space_query_id=" + queryId;
                        break;
                    case "6":
                        searchQuery = "&resource_query_id=" + queryId;
                        break;
                }
            }

            this.itemType = parseInt(itemTypeId);
            this.itemId = parseInt(itemId);
            this.query = searchQuery;
            this.view = comptype as AvailCompType;
        }
    }

    Service = AvailService;
}
